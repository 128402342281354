@import "../../common/styles/global-variables.scss";

.button-to-top {
    margin: 80px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @include square(32px);
    border-radius: 50%;
    background-color: $silverchalice;
    cursor: pointer;
    overflow: hidden;

    svg {
        width: 16px;
        fill: $white;
        transform: rotate(90deg);
    }
}