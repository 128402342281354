@import "../../common/styles/global-variables.scss";

.site-hint {
  position: absolute;
  bottom: 12px;
  left: calc(100% - 45px);
  @include square(16px);
  background-color: $nobel;
  border-radius: 50%;
  color: $white;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  z-index: 1;
  cursor: pointer;
  transition: .3s;

  &:hover {
    background-color: $havelockblue;
  }

  &--opened {
    background-color: $havelockblue;

    .site-hint__content {
      display: block;
    }
  }

  &__content-container {
    position: absolute;
    right: calc(100% + 16px);
    width: 100vw;
    display: flex;
    justify-content: flex-end;
    z-index: 4;
  }

  &__content {
    display: none;
    max-width: 250px;
    padding: 30px 16px;
    font-size: 14px;
    text-align: left;
    color: $dovegray;
    background-color: $white;
    border: 1px solid $mercury;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
    

    &::after {
      position: absolute;
      right: -10px;
      top: calc(50% - 10px);
      content: "";
      width: 20px;
      height: 20px;
      background-color: $white;
      border-top: 1px solid $mercury;
      border-right: 1px solid $mercury;
      transform: rotate(45deg);
      z-index: 3;
    }

    @media(min-width: $extra-large) {
      max-width: 440px;
    }
  }

  &__closer {
    position: absolute;
    top: 10px;
    right: 16px;
    width: 10px;
    height: 10px;

    svg {
      width: 10px;
      height: 10px;
      fill: $nobel;
      cursor: pointer;
    }
  }
}
