@import "../../common/styles/global-variables.scss";

.flag-phone-block {

	&--error {

		input {
			border-left: none;
			background: #fff6f6!important;
		    border-color: #e0b4b4!important;
		    color: #9f3a38!important;
		}

		.ui.inline.dropdown.label {
			background: #fff6f6!important;
			background-color: #fff6f6!important;
			border-color: #e0b4b4!important;
			color: #9f3a38!important;
			border: 1px solid #e0b4b4!important;
			border-right: none!important;
		}

		.ui.inline.dropdown.label:first-child {
			background: #fff6f6!important;
			background-color: #fff6f6!important;
			border-color: #e0b4b4!important;
			color: #9f3a38!important;
			border: 1px solid #e0b4b4!important;
			border-right: none!important;
		}
	}
}