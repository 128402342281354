@import "../../common/styles/global-variables.scss";

.link {
    color: $abbey;
    font-size: 14px;

    &:hover {
        text-decoration: none;
        color: $abbey;
    }
}