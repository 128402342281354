@import "../../common/styles/global-variables.scss";

.site-footer {
    padding: 16px 0;
    background-color: $tangaroa;

    &__item {
        font-size: 12px;
        line-height: 1.866;
        color: rgba($white, .8);

        &:nth-child(2) {
            margin-top: 10px;
        }

        @media(min-width: $medium) {
            &:nth-child(2) {
                margin-top: 0;
                text-align: right;
            }
        }

        @media(min-width: $extra-large) {
            &:nth-child(2) {
                text-align: center;
            }
        }
    }

    &__terms-of-service {
        color: $white;
        text-decoration: underline;
        font-weight: bold;
        cursor: pointer;
    }

    &__policy {
        text-decoration: underline;
        font-weight: bold;
        cursor: pointer;
    }

    &__copyrights {
        color: rgba($white, .5);

        @media(min-width: $medium) {
            text-align: right;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        font-size: 13px;
        color: rgba($white, .8);

        &:hover {
            color: rgba($white, .8);
        }

        @media(min-width: $medium) {
            justify-content: flex-end;
        }

        @media(min-width: $extra-large) {
            justify-content: center;
        }
    }

    &__link-svg {
        margin-right: 6px;

        svg {
            @include size(16px, 11px);
            fill: rgba($white, .5);
        }
    }
}