@import "../../common/styles/global-variables.scss";

.login-form {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(125deg, $royalblue, $bluemarguerite);
    color: rgba($white, .8);
    z-index: 6;
    overflow-y: scroll;

    .ui.form .field .ui.input input, .ui.form .fields .field .ui.input input {
        border: solid 1px rgba(255, 255, 255, 0.4);
        color: rgba($white, .8);
        font-size: 14px;
        background-color: rgba(255, 255, 255, 0.2);

        &:focus {
            background-color: $white!important;
            color: $bluezodiak!important;
        }
    }

    .container-fluid,  {
        height: 100vh;
    }

    .row {
        height: 100%; 
    }

    &__title {
        margin-bottom: 64px;
        display: block;
        width: 100%;
        color: $white;
        font-size: 32px;
        line-height: 36px;
        font-weight: bold;
        text-align: center;
    }

    input {
        transition: .3s;

        &:hover {
            background-color: rgba(255,255,255,0.25)!important;
        }
    }

    &__label {
        color: rgba($white, .8)!important;
        font-size: 13px!important;
        font-weight: normal!important;

        span {
            color: rgba($white, .8);
        }
    }

    &__login-form-checkbox {

        .site-form__label {
            margin-bottom: 0!important;
            cursor: pointer!important;

            &:hover {
                &::before {
                    border: solid 1px $white;
                    background-color: rgba(255,255,255,0.25)!important;
                }
            }
        }
    }

    .ui.checkbox input:checked~.box:after, .ui.checkbox input:checked~label:after {
        color: $royalblue; 
    }

    .ui.checkbox input:checked~.box:before, .ui.checkbox input:checked~label:before {
        background-color: $white!important; 
    }

    .site-form__checkbox.ui.checkbox .site-form__label {
        color: rgba($white, .8)!important;
        font-size: 13px!important;
        font-weight: normal!important;

        &::before {
            border: solid 1px $white;
            background-color: transparent;
            transition: .3s;
        }
    }

    &__login-form-checkbox-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__restore-btn {
        font-size: 13px;
        color: rgba($white, .8);
        cursor: pointer;
        transition: .3s;

        &:hover {
            color: $white;
            text-decoration: underline;
        }
    }

    &__button {
        border: solid 1px $white;
        background-color: transparent;
        width: 100%;
        transition: .3s;

        &:hover {
            background-color: rgba(255,255,255,0.25)!important;
        }
    }

    &__notice {
        color: rgba($white, .8);
        font-size: 12px;
        line-height: 18px;

        a {
            font-weight: bold;
            color: $white;

            &:hover {
                color: $white;
                text-decoration: underline;
            }
        }
    }

    &__entry-link {
        margin-top: 60px;
        display: inline-block;
        font-weight: normal;
        font-size: 12px;
        color: rgba($white, .8);
        line-height: 18px;
        cursor: pointer;
        transition: .3s;

        &:hover {
            color: $white;
            text-decoration: underline;
        }

        @media(min-width: $medium) {
            margin-top: 45px;
        }

        @media(min-height: 768px) {
            margin-top: 197px;
        }
    }

    &__login {
        display: block;
        padding-top: 140px;
        padding-bottom: 80px; 
        width: 100vw;
        transition: .3s;

        @media(min-width: 340px) {
            width: 330px;
            left: calc(50% - 165px)
        }
    }

    &__restore {
        display: none;
        padding-top: 140px;
        padding-bottom: 80px;
        width: 100vw; 
        transition: .3s;

        @media(min-width: 340px) {
            width: 330px;
            left: calc(50% - 165px)
        }
    }

    &--restore-view {

        .login-form__login {
            display: none;
        }

        .login-form__restore {
            display: block;
        }
    }

    &__use-rules {
        cursor: pointer!important;
    }
}