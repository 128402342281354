@import "../../common/styles/global-variables.scss";

.site-pagination {
    max-width: 700px;
    text-align: center;

    @media(min-width: $extra-large) {
        text-align: left;
    }

    &__container {
        margin: auto;
        display: inline-flex;
        justify-content: center;
        border-top: 1px solid $havelockblue;
        border-bottom: 1px solid $havelockblue;
    }

    &__btn {
        width: 40px;
        height: 40px;
        border-left: 1px solid $havelockblue;
        cursor: pointer;
        text-align: center;
        transition: .3s;

        a {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 0 12px;
            ////
            font-size: 14px;
            line-height: 40px;
            color: $havelockblue;
            text-align: center;
            transition: .3s;

            @media(min-width: $medium) {
                display: inline-block;
            } 
        }

        &--current {
            background-color: $havelockblue;

            a {
                color: $white;
            }
        }

        &:hover {
            background-color: $havelockblue;

            a {
                color: $white;
            }
        }
    }

    &__btn-center {
        cursor: default;

        span {
            font-size: 14px;
            line-height: 40px;
            color: $havelockblue;
            text-align: center;
        }
    }

    &__btn-first {
        width: auto;
        display: none;

        @media(min-width: $medium) {
            display: inline-block;
        }
    }

    &__btn-last {
        width: auto;
        display: none;
        border-right: 1px solid $havelockblue;

        @media(min-width: $medium) {
            display: inline-block;
        }
    }

    &__btn-previous {
        width: 83px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            display: none;
        }

        svg {
            display: block;
            height: 16px;
            fill: $havelockblue;
        }

        @media(min-width: $medium) {
            width: auto;

            svg {
                display: none;
            }

            span {
                display: inline;
            }
        }
    }

    &__btn-next {
        width: 83px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid $havelockblue;

        span {
            display: none;
        }

        svg {
            display: block;
            height: 16px;
            fill: $havelockblue;
        }


        @media(min-width: $medium) {
            width: auto;
            border-right: none;

            svg {
                display: none;
            }

            span {
                display: inline;
            }
        }
    }
}