@import "../../common/styles/global-variables.scss";

.section {
  position: relative;
  max-width: 100vw;
  width: 100%;
  overflow: hidden;

  h2 {
    font-family: $font-family-futura-bold;
    font-size: 26px;
    line-height: 1.38;
    color: $abbey;
    text-transform: uppercase;
  }
}