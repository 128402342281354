@keyframes from-top-to-down {
  from {
    transform: translateY(-100vh);
  }
  to {
    transform: none;
  }
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}