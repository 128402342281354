@import "../../common/styles/global-variables.scss";

.logo {
    position: relative;
    display: block;
    z-index: 2;
    @include size(97px, 57px);

        @media(min-width: $medium) {
            @include size(115px, 68px);
        }

        @media(min-width: $extra-large) {
            @include size(121px, 72px);
        }

    &--white {
        .logo__img-white {
            opacity: 1;
        }

        .logo__img-dark {
            opacity: 0;
        }
    }    

    &__img {
        position: absolute;
        top: 0;
        left: 0;
        @include square(100%);
        transition: .3s;
    }

    &__img-dark {
        opacity: 1;
    }

    &__img-white {
        opacity: 0;
    }
}