@import "reset.scss";
@import "normalize.scss";
@import "fonts.scss";
@import "global-variables.scss";

html {
	max-width: 100vw;
	overflow: hidden;
}

body {
	max-width: 100vw;
	overflow-x: hidden;
	font-family: $font-family-roboto;
	-webkit-print-color-adjust: exact;
}

* {
	-moz-box-sizing: border-box!important;
	-webkit-box-sizing: border-box!important;
	box-sizing: border-box!important;
}