@import "../../common/styles/global-variables.scss";

.main-menu {
    position: fixed;
    width: 100vw;
    height: 100vh;
    padding-top: var(--site-header__height);
    top: 0;
    left: -10000px;
    background-image: linear-gradient(-45deg, #6976C0 0%, $royalblue 100%);
    transition: .5s;

    @media(min-width: $medium) {
        display: none;
        // position: relative;
        // left: 0;
        // width: auto;
        // height: auto;
        // padding-top: 0;
        // background-image: none;
    }

    &--opened {
        left: 0;
    }

    &__list {
        height: 100%;
        display: flex;
        flex-direction: column;
        list-style-type: none;
        justify-content: center;
        align-items: center;

        @media(min-width: $extra-large) {
            flex-direction: row;
            align-items: flex-start;
        }

        @media(min-width: $desktop-1440) {
            margin-right: 65px;
        }
    }

    &__item {
        padding: 18px 0;

        @media(min-width: $extra-large) {
            padding: 0;

            &:not(:last-child) {
                margin-right: 25px;
            }
        }

        &--extra {
            padding: 18px 0;
            text-align: center;

            @media(min-width: $extra-large) {
                display: none;
            }

            .main-menu__link {
                font-size: 14px;
                font-weight: 400;

                &:not(:last-child)  {
                    margin-right: 30px;
                }
            }

            svg {
                margin-right: 5px;
                fill: $white;
                @include square(16px);
            }
        }
    }

    &__link {
        font-size: 18px;
        font-weight: 500;
        color: $white;
        vertical-align: top;

        &:hover {
            color: $white;
        }

        @media(min-width: $extra-large) {
            font-size: 15px;
            color: rgba($abbey, .9);
        }
    }

    &__check-button {
        margin-bottom: 30px;
        display: flex;
        flex-direction: row;
        padding: 10px 24px;
        align-items: center;
        justify-content: center;
        width: 145px;
        height: 40px;
        border-radius: 100px;
        border: 1px solid $white;
        font-weight: 500;
        z-index: 2;
        transition: .3s;
    }    

    &__check-button-icon {
        margin: 0 16px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        @include square(20px);

        svg {
            display: block;
            max-width: 100%;
            max-height: 100%;
            fill: $white;
        }
    }

    &__check-button-text {
        font-size: 14px;
        color: $white;
        font-weight: 600;
    }
}