@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin square($size) {
  @include size($size, $size);
}

@mixin breakpoint-desktop {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin breakpoint-tablet-down {
  @media (max-width: 1199.98px) {
    @content;
  }
}
@mixin breakpoint-tablet-up-992 {
  @media (min-width: 992px) {
    @content;
  }
}
@mixin breakpoint-tablet-up-768 {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin breakpoint-tablet-hard-992 {
  @media (min-width: 992px) and (max-width: 1199.98px) {
    @content;
  }
}
@mixin breakpoint-tablet-hard-768 {
  @media (min-width: 768px) and (max-width: 1199.98px) {
    @content;
  }
}
@mixin breakpoint-mobile-992 {
  @media (max-width: 991.98px) {
    @content;
  }
}
@mixin breakpoint-mobile-768 {
  @media (max-width: 767.98px) {
    @content;
  }
}