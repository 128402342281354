/* == FONTS == */


/* == Roboto == */

/* cdn */


/* == Futura == */

@font-face{
  font-family: "Futura-Bold";
  src: url("../../fonts/Futura/FuturaPT.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}