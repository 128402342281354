@import "../../common/styles/global-variables.scss";

.popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background-color: rgba($black, 0.5);
  z-index: 16;
  color: $dovegray;

  &--opened {
      
    .popup__content {
      transform: none;
    }
  }

  &--terms-of-service {

  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    max-width: 230px;
    font-weight: bold; 

    &--big {
      margin-top: 26px;
      text-transform: uppercase;
      font-size: 27px;
    }

    @media(min-width: $small) {
      max-width: 550px;
    }

    @media(min-width: $medium) {
      max-width: 740px;

      &--big {
        font-size: 48px;
      }
    }

    @media(min-width: $large) {
      max-width: 940px;
    }

    @media(min-width: $extra-large) {
      max-width: 1150px;

      &--big {
        font-size: 50px;
      }
    }
  }

  &__closer {
    margin-top: 3px;
    @include square(12px);

    svg {
      display: block;
      @include square(12px);
      cursor: pointer;
      fill: rgba($dovegray, .2);
    }
  }

  &__content {
    margin: 10px;
    background-color: $white;
    border-radius: 2px;
    box-shadow: 0 10px 30px 0 rgba(67, 77, 82, 0.8);
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    transform: translateY(-1000px);
    transition: 0.3s;
    //for random popup
    max-width: 600px;
    padding: 15px;
    border-radius: 6px;
    font-size: 16px;
    line-height: 26px;

    @media(min-width: $medium) {
      margin: 30px auto;
    }
  }

  &__container {
    margin-top: 30px;

    p {
      margin-bottom: 13px;
    }

    ul {
      margin-bottom: 13px;
    }
  }
}
