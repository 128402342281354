@import "mixins.scss";
@import "effects.scss";
@import "fonts.scss";

/* Colors */

$black: #000000;
$white: #ffffff;
$thunderbird: #d52b1e;
$mercury: #e7e7e7;
$abbey: #545457;
$dovegray: #707070;
$silverchalice: #a4a4a4;
$dustygray: #949494;
$shipgray: #424245;
$havelockblue: #4a90e2;
$royalblue: #487ede;
$bluemarguerite: #6976c0;
$bluezodiak: #12355B;
$tangaroa: #041a44;
$wildsand: #f5f5f5;
$titanwhite: #fafaff;
$nobel: #b3b3b3;
$pastelgreen: #60e24a;
$mainshaft: #353535;
$silver: #c8c8c8;
$gallery: #ebebeb;
$laserlemon: #ffff66;
$sun: #f8a41a;
$cosmos: #fedada;
$mandy: #E0495D;

/* Fonts */

$font-family-roboto: 'Roboto', sans-serif;
$font-family-futura-bold: "Futura-Bold";
$awesome: "FontAwesome";

//bootstrap
$extra-large: 1200px;
$large: 992px;
$medium: 768px;
$small: 576px;

//custom
$desktop-1440: 1440px;
$desktop-1920: 1920px;
$desktop-2550: 2550px;
$desktop: 1280px;
$album-tablet: 1024px;
$tablet: 768px;
$mobile: 320px;

//css variables

:root {
    --site-header__height: 91px;
  }
  
  @media (min-width: 768px) {
    :root {
      --site-header__height: 102px;
    }
  }

  @media (min-width: 1200px) {
    :root {
      --site-header__height: 106px;
    }
  }