@import "../../common/styles/global-variables.scss";

.policy {
    max-width: 600px;
    padding: 15px;
    border-radius: 6px;
    font-size: 16px;
    line-height: 26px;

    &__text {
        margin-top: 30px;
    }

    &__subtitle {
        font-weight: bold;
    }

    &__text-block {

        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }

    &__link {
        color: $havelockblue;

        &:hover {
            text-decoration: underline;
            color: $havelockblue;
        }
    }
}