@import "../../common/styles/global-variables.scss";

.site-form {
    margin-top: 40px;

    input {
        font-size: 14px!important;
        padding-right: 40px!important;
    }

    input:focus {
        font-size: 14px!important;
    }

    i.dropdown.icon::before {
        color: $dovegray;
    }

    &__item {
        position: relative;
        margin-bottom: 42px!important;

        .default.text {
            font-size: 14px!important; 
        }

        &--error {
            //margin-bottom: 45px!important;

            input {
                border: 1px solid $thunderbird!important;
                background-color: rgba($thunderbird, .3)!important;
            }

            .ui.selection.dropdown {
                border: 1px solid $thunderbird!important;
                background-color: rgba($thunderbird, .3)!important;
            }

            .error {
                display: block;
            }
        }
    }

    .ui.form .field {
        margin-bottom: 30px!important; 
    }

    .ui.form .fields {
        flex-wrap: wrap;
    }

    &__checkbox.ui.checkbox {

        .site-form__label {
            font-size: 16px!important;
        }
    }
    
    &__error-text {
        position: absolute;
        top: calc(100% + 3px);
        left: 15px;
        color: $thunderbird;
        font-size: 10px;
    }

    &__label {
        width: 100%!important;
        margin-bottom: 8px!important;
        font-size: 14px!important;
        color: rgba($shipgray, .7)!important;
        font-weight: 500!important;

        span {
            color: $thunderbird;
        }
    }

    .ui.checkbox {
        .site-form__label {
            font-size: 14px;
            color: rgba($shipgray, .7);
            font-weight: 500;
            cursor: pointer;
        }
    }

    &__button {
        margin-top: 26px;
        padding: 13px 0;
        width: 100%;
        border-radius: 100px;
        background-color: $havelockblue;
        outline: none;
        border: none;
        color: $white;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        cursor: pointer;

        @media(min-width: $extra-large) {
            width: 220px;
        }

        &--gray {
            background-color: $white;
            color: $silverchalice;
            border: solid 1px $silverchalice;
        }
    }

    &__add-button {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        color: rgba($shipgray, .8);
        cursor: pointer;
    }

    &__question {
        position: absolute;
        bottom: 14px;
        left: calc(100% - 45px);
        @include square(16px);
        background-color: $nobel;
        border-radius: 50%;
        color: $white;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        z-index: 1;
        cursor: pointer;
    }

    &__help {
        position: absolute;
        top: calc(100% + 16px);
        font-size: 13px;
        color: $silverchalice;
        line-height: 1.62;
    }

    .ui.checkbox input:checked~.box:before, .ui.checkbox input:checked~label:before {
        background-color: $nobel;
        border: $nobel;
    }

    .ui.checkbox input:checked~.box:after, .ui.checkbox input:checked~label:after {
        color: $white;
    }

    .ui.checkbox+label::before {
        transition: .3s;
    }

    .ui.checkbox:hover {
        label:before {
            background-color: $nobel!important;
            border: solid 1px $nobel!important;
        }
    }

    &__radio {

        input {
            border: solid 1px $nobel;
        }

        .ui.radio.checkbox .box, .ui.radio.checkbox label {
            font-size: 14px!important;
            color: rgba($shipgray, .7)!important;
            font-weight: 500!important;
        }

        .ui.radio.checkbox input:checked~.box:before, .ui.radio.checkbox input:checked~label:before {
            background-color: $white!important; 
            border: 1px solid $pastelgreen!important;
        }

        .ui.radio.checkbox input:checked~.box:after, .ui.radio.checkbox input:checked~label:after {
            background-color: $pastelgreen!important; 
        }

        @media(min-width: $extra-large) {

            .site-form__label {
                margin-bottom: 22px!important;
            }
        }
    }

    &__checkbox {

        a {
            color: $havelockblue!important;
            text-decoration: underline!important;
            font-weight: 500!important;
            transition: .3s;

            &:hover {
                text-decoration: none!important;
            }
        }
    }

    @media screen and (-webkit-min-device-pixel-ratio: 0) {
        select:focus,
        textarea:focus,
        input:focus {
        font-size: 14px!important;
        }
    }
}