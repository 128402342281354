@import "../../common/styles/global-variables.scss";

.sandwich {
  position: relative;
  @include size(26px, 22px);
  cursor: pointer;
  transition: .3s;
  z-index: 2;
  
  @media(min-width: $medium) {
    display: none;
  }

  &--view-changed {

    .sandwich__closer {
      opacity: 1;
    }

    .sandwich__menu {
      opacity: 0;
    }
  }

  &__menu {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    fill: $abbey;
    @include square(100%);
    transition: .3s;
  }

  &__closer {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    fill: $white;
    @include square(100%);
    transition: .3s;
  }
}