@import "../../common/styles/global-variables.scss";

.site-header {
    position: relative;
    // top: 0;
    // left: 0;
    padding: 15px 0 18px;
    background-color: $white;
    border-bottom: 1px solid $mercury;
    z-index: 2;

    @media(min-width: $extra-large) {
        padding-right: 17px; //for scrollbar
    }

    &__logout {
        display: none;
        cursor: pointer;

        svg {
            width: 20px;
            height: 25px;
            fill: $white;
        }
    }

    &__check-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        z-index: 2;
        transition: .3s;
        cursor: pointer;

        &:hover {
            background-color: rgba(84,84,87,0.05);

            a {
                color: rgba($dovegray, .9);
            }
        }

        &:active {
            box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
        }

        @media(min-width: $medium) {
            flex-direction: row;
            padding: 9px 0 9px;
            width: 145px;
            height: 40px;
            border-radius: 100px;
            border: solid 1px $silverchalice;
        }
    }

    &__check-button-icon {
        margin-bottom: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include square(20px);

        @media(min-width: $medium) {
            margin: 0 16px 0 0;
        }

        svg {
            display: block;
            max-width: 100%;
            max-height: 100%;
            fill: $dovegray;
        }
    }

    &__check-button-text {
        font-size: 12px;
        color: rgba($dovegray, .9);
        font-weight: 600;

        @media(min-width: $medium) {
            font-size: 14px;
        }
    }

    &__contacts {
        display: none;
        flex-direction: column;

        a {
            display: block;
            font-size: 14px;
            color: rgba($abbey, .9);

            &:first-child {
                margin-bottom: 8px;
            }
        }

        @media(min-width: $medium) {
            display: flex;
        }
    }

    &__info {
        margin-right: 24px;
        margin-left: 24px;
        display: none;
        flex-direction: column;

        span {
            display: block;
            font-size: 14px;
            color: rgba($abbey, .9);

            &:first-child {
                margin-bottom: 8px;
            }

            &:last-child {
                font-weight: 700;
            }
        }

        @media(min-width: $medium) {
            display: flex;
        }
    }
}